

import React from 'react';
import './UnderConstruction.css';

const UnderConstruction = () => {
  return (
    <div className="under-construction-container">
      <div className="construction-message">
        <h1>Website Under Construction</h1>
        <p>We're working hard to bring you an amazing experience. Stay tuned!</p>
        <img src={process.env.PUBLIC_URL + '/construction-icon.png'} alt="Construction Icon" />
      </div>
    </div>
  );
};

export default UnderConstruction;
